import { useEffect, useState } from "react";
import PwaAndroidDeviceDialog from "./pwaAndroidDeviceDialog";
import PwaAppleDeviceDialog from "./pwaAppleDeviceDialog";
import { useDispatch } from "react-redux";
import { setShowPwaPrompt } from "../../store/actions";

export default function PwaCheck(props) {
  const isApplePlatform = () => {
    return (
      (["iPhone", "iPad"].includes(window.navigator.platform) ||
        (window.navigator.userAgent.match(/Mac/) &&
          window.navigator.maxTouchPoints &&
          window.navigator.maxTouchPoints > 2)) &&
      "serviceWorker" in window.navigator
    );
  };

  const isAndroidPlatform = () => {
    return (
      navigator.userAgentData &&
      navigator.userAgentData.mobile &&
      "serviceWorker" in window.navigator
    );
  };

  const isStandalone = () => {
    return window.matchMedia("(display-mode: standalone)").matches;
  };

  const ignorePrompt = () => {
    const iosChecked = localStorage.getItem("PWA_IOS_CHECKED");
    const androidChecked = localStorage.getItem("PWA_ANDROID_CHECKED");
    return (
      (iosChecked !== null && iosChecked === "true") ||
      (androidChecked !== null && androidChecked === "true")
    );
  };

  const [applePrompt, setApplePrompt] = useState(false);
  const [androidPrompt, setAndroidPrompt] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isApplePlatform() && !isStandalone()) {
      setApplePrompt(true);
    } else if (isAndroidPlatform() && !isStandalone()) {
      setAndroidPrompt(true);
    }
    dispatch(setShowPwaPrompt(!ignorePrompt()));
  }, [dispatch]);

  return (
    <>
      {applePrompt ? (
        <PwaAppleDeviceDialog
          appleIcon={props.appleIcon}
          onClose={(checked) => {
            localStorage.setItem("PWA_IOS_CHECKED", checked);
            dispatch(setShowPwaPrompt(false));
          }}
        />
      ) : (
        <></>
      )}
      {androidPrompt ? (
        <PwaAndroidDeviceDialog
          androidIcon={props.androidIcon}
          onClose={(checked) => {
            localStorage.setItem("PWA_ANDROID_CHECKED", checked);
            dispatch(setShowPwaPrompt(false));
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
