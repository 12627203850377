import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStrapiURLClientSide } from "../../utils/api";
import { axiosGetRequest } from "../../utils/clientUtil";

export default function PwaAppleDeviceDialog(props) {
  const { onClose, appleIcon } = props;
  const { t: tPublic } = useTranslation("public");
  const [manifest, setManifest] = useState({});

  const showPwaPromptState = useSelector(
    (state) => state.general.showPwaPrompt
  );

  const handleClose = () => {
    onClose(true);
  };

  const fetchManifestJson = useCallback(async () => {
    const manifestResponse = await axiosGetRequest(
      getStrapiURLClientSide("/assets/manifest.json")
    );
    setManifest(manifestResponse.response.data);
  }, []);

  useEffect(() => {
    fetchManifestJson();
  }, [fetchManifestJson]);

  return (
    <>
      <aside id="pwa-ios-install-prompt">
        <article
          className={`prompt apple prompt-body ${
            showPwaPromptState && manifest ? "available" : ""
          }`}
        >
          <div className="icon">
            <img className="icon-image" src={appleIcon} alt={manifest.name} />
          </div>
          <div className="about">
            <div className="name">{manifest.name}</div>
            <div className="description">{manifest.description}</div>
          </div>
          <div className="prompt-description">
            {tPublic("pwaCheckIOSPromptDescription")}
          </div>
          <div className="how-to-container">
            <div className="how-to-description">
              <div className="description-step">
                <div className="svg-wrap">
                  <svg id="pwa-share" width="25" height="32">
                    <g>
                      <path d="m12.51122,20.7648c0.52277,0 0.99149,-0.43265 0.99149,-0.95542l0,-14.87204l-0.09015,-2.0731l1.11768,1.15371l2.27135,2.37952c0.18028,0.19833 0.43265,0.30647 0.68502,0.30647c0.50476,0 0.90134,-0.37857 0.90134,-0.88332c0,-0.27039 -0.10817,-0.46869 -0.28844,-0.64895l-4.86721,-4.74103c-0.25237,-0.25237 -0.46869,-0.32448 -0.72106,-0.32448c-0.25237,0 -0.46869,0.0721 -0.72106,0.32448l-4.88523,4.74103c-0.18028,0.18028 -0.28844,0.37857 -0.28844,0.64895c0,0.50476 0.37857,0.88332 0.90134,0.88332c0.23436,0 0.50476,-0.10814 0.68502,-0.30647l2.27135,-2.37952l1.11768,-1.17172l-0.0721,2.09112l0,14.87204c0,0.52277 0.45066,0.95542 0.99145,0.95542l-0.00002,0l-0.00001,-0.00001zm-8.13005,10.96022l16.22403,0c2.86626,0 4.30841,-1.42412 4.30841,-4.23627l0,-14.27715c0,-2.79415 -1.44215,-4.23627 -4.30841,-4.23627l-3.96589,0l0,2.00095l3.92985,0c1.47819,0 2.34345,0.79316 2.34345,2.34345l0,14.06085c0,1.5503 -0.86527,2.34347 -2.34345,2.34347l-16.15194,0c-1.49625,0 -2.32547,-0.79317 -2.32547,-2.34347l0,-14.06085c0,-1.55029 0.82923,-2.34345 2.32547,-2.34345l3.96589,0l0,-2.00095l-4.00196,0c-2.84821,0 -4.29035,1.4241 -4.29035,4.23627l0,14.27715c0,2.81217 1.44215,4.23627 4.29035,4.23627l0.00001,0l0.00001,0z" />
                    </g>
                  </svg>
                </div>
                <div className="step-text">{tPublic("pwaCheckIOPromptStep1")}</div>
              </div>
              <div className="description-step">
                <div className="svg-wrap">
                  <svg id="pwa-add" width="25" height="25">
                    <g>
                      <path d="m23.40492,1.60784c-1.32504,-1.32504 -3.19052,-1.56912 -5.59644,-1.56912l-10.65243,0c-2.33622,0 -4.2017,0.24408 -5.5267,1.56912c-1.32504,1.34243 -1.56911,3.17306 -1.56911,5.50924l0,10.5827c0,2.40596 0.22665,4.254 1.55165,5.57902c1.34246,1.32501 3.19052,1.5691 5.59647,1.5691l10.60013,0c2.40592,0 4.2714,-0.24408 5.59644,-1.5691c1.325,-1.34245 1.55166,-3.17306 1.55166,-5.57902l0,-10.51293c0,-2.40596 -0.22666,-4.25401 -1.55166,-5.57901zm-0.38355,5.21289l0,11.24518c0,1.51681 -0.20924,2.94643 -1.02865,3.78327c-0.83683,0.83685 -2.30134,1.0635 -3.81815,1.0635l-11.33234,0c-1.51681,0 -2.96386,-0.22665 -3.80073,-1.0635c-0.83683,-0.83684 -1.04607,-2.26646 -1.04607,-3.78327l0,-11.19288c0,-1.5517 0.20924,-3.01617 1.02865,-3.85304c0.83687,-0.83683 2.31876,-1.04607 3.87042,-1.04607l11.28007,0c1.51681,0 2.98132,0.22666 3.81815,1.06353c0.81941,0.81941 1.02865,2.26645 1.02865,3.78327zm-10.53039,12.08205c0.64506,0 1.02861,-0.43586 1.02861,-1.13326l0,-4.34117l4.53294,0c0.66252,0 1.13326,-0.36613 1.13326,-0.99376c0,-0.64506 -0.43586,-1.02861 -1.13326,-1.02861l-4.53294,0l0,-4.53294c0,-0.6974 -0.38355,-1.13326 -1.02861,-1.13326c-0.62763,0 -0.99376,0.45332 -0.99376,1.13326l0,4.53294l-4.51552,0c-0.69737,0 -1.15069,0.38355 -1.15069,1.02861c0,0.62763 0.48817,0.99376 1.15069,0.99376l4.51552,0l0,4.34117c0,0.66252 0.36613,1.13326 0.99376,1.13326z" />
                    </g>
                  </svg>
                </div>
                <div className="step-text">{tPublic("pwaCheckIOPromptStep2")}</div>
              </div>
            </div>
          </div>
          <div className="action-buttons">
            <button
              className="dialog-button button install"
              onClick={handleClose}
            >
              {tPublic("pwaCheckIOPromptButtonClose")}
            </button>
          </div>
        </article>
      </aside>
      <style jsx>{`
        .prompt {
          position: fixed;
          max-width: 390px;
          width: auto;
          margin: 0 auto;
          right: 0;
          left: 0;
          font-size: 15px;
          bottom: 0;
          transition: transform 0.4s cubic-bezier(0.33, 1, 0.66, 1),
            opacity 0.5s cubic-bezier(1, 0, 1, -2), border 0.35s ease-in-out;
          overflow: hidden;
          pointer-events: none;
          opacity: 0;
          visibility: visible;
          transform: translateY(100%);
          will-change: opacity, transform;
          z-index: 1000;

          &.prompt-body {
            display: grid;
            grid-template-columns: 95px auto 1fr;
            grid-template-rows: 1fr auto;

            grid-template-areas:
              "icon description"
              "welcome welcome"
              "how-to how-to"
              "button button";

            border-radius: 10px;

            background-color: rgba(255, 255, 255, 0.75);
            filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.5));
            backdrop-filter: blur(8px);
            -webkit-backdrop-filter: blur(8px);
            border-bottom: 1px solid #e0e0e0;
          }

          &.available {
            opacity: 1;
            pointer-events: unset;
            transform: translateY(0);
            transition: transform 0.5s cubic-bezier(0.33, 1, 0.66, 1),
              border 0.35s ease-in-out;
          }

          .icon {
            display: flex;
            align-items: center;
            grid-area: icon;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .icon-image {
              width: 64px;
              height: 64px;
              margin: 0 auto;
              border-radius: 6px;
            }
          }

          .prompt-description {
            font-size: 15px;
            grid-area: welcome;
            text-align: left;
            font-weight: 400;
            padding: 15px;
            width: auto;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          }

          .how-to-container {
            opacity: 1;
            max-height: 400px;
            overflow-y: auto;
            grid-area: how-to;

            .how-to-description {
              display: grid;
              user-select: none;
              grid-auto-columns: 1fr;
              grid-template-rows: 1fr 1fr;
              gap: 10px;
              padding: 15px 15px 0 15px;

              .description-step {
                display: inline-flex;
                flex-direction: row;
                gap: 15px;
                align-items: center;

                font-size: 14px;
                .svg-wrap {
                  position: relative;
                  display: inline-flex;
                  flex-shrink: 0;

                  width: 50px;
                  height: 50px;
                  align-items: center;

                  border-radius: 6px;

                  #pwa-share,
                  #pwa-add {
                    margin: 0 auto;
                  }

                  #pwa-share {
                    fill: #527afb;
                  }
                  #pwa-share {
                    margin-bottom: 4px;
                    transform: scale(0.75);
                  }
                  #pwa-add {
                    transform: scale(0.8);
                    fill: #000000;
                  }

                  .step-count {
                    position: absolute;
                    line-height: 12px;
                    top: 3px;
                    left: 4px;
                  }
                }
                .step-text {
                  font-weight: 600;
                  text-align: left;
                }
              }
            }
          }

          .action-buttons {
            grid-area: button;
            grid-column-start: 1;
            grid-column-end: 3;
            padding: 15px;
          }

          .about {
            display: grid;
            grid-template-rows: 35px 60px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            grid-area: description;

            .name,
            .description {
              padding-right: 15px;
              overflow: hidden;
            }
            .name {
              font-weight: 600;
              padding-top: 10px;
              padding-right: 10px;
              line-height: 22px;
              align-items: flex-start;
              display: flex;
            }
            .description {
              font-size: 12px;
              line-height: 15px;

              margin-bottom: 15px;
              overflow-y: auto;
            }
          }
          .dialog-button {
            display: grid;
            height: 50px;
            width: 100%;
            line-height: 50px;
            border-radius: 8px;
            color: #333;

            &.button {
              border: none;
              outline: none;
              font-family: system-ui, -apple-system, "HelveticaNeue",
                BlinkMacSystemFont;
              cursor: pointer;
              user-select: none;
              font-weight: 400;
              font-size: 17px;
              padding: 0;
              margin: 0;
              background-color: #ffffff;

              &.install {
                padding: 0 15px;
                text-align: left;

                .button-text {
                  opacity: 0;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  transition: opacity 0.4s ease-in-out 0.1s;
                  overflow: hidden;

                  > span {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }

                  > svg {
                    flex-shrink: 0;
                    transform: scale(0.8);
                  }

                  &.show {
                    opacity: 1;
                    visibility: visible;
                    width: auto;
                    height: auto;
                  }
                  &.hide {
                    opacity: 0;
                    position: absolute;

                    width: 0px;
                    height: 0px;
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
        @media (min-width: 667px) {
          #pwa-install-element {
            .prompt.apple {
              top: 0;
              bottom: unset;
              transform: translateY(calc(-100% - 40px));

              right: 150px;
              left: auto;

              &.available {
                top: 28px;
                bottom: auto;
                max-height: calc(95vh - 28px);
              }
            }
          }
        }

        @media (min-width: 667px) and (max-width: 1366px) {
          #pwa-install-element {
            .prompt.apple {
              right: max(28px, env(safe-area-inset-right, 28px));
              left: auto;
            }
          }
        }

        @media (max-width: (667px - 1px)) {
          #pwa-install-element {
            .prompt.apple {
              .description-step .step-text {
                font-size: 13px;
              }
              &.available {
                max-height: calc(85vh - 20px);
              }
              &.dialog-body {
                filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px 0px 15px);
              }
            }
          }
        }
        @media (max-width: 428px) {
          #pwa-install-element {
            .prompt.apple.dialog-body {
              max-width: 100vw;
              filter: drop-shadow(rgba(0, 0, 0, 0.5) 0px -5px 10px);
              .action-buttons {
                padding-bottom: max(15px, env(safe-area-inset-bottom));
              }
              &.available {
                bottom: 0px;
                max-height: 85vh;
              }

              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }
        @media (max-height: 548px) {
          #pwa-install-element {
            .prompt.apple.dialog-body {
              &.available {
                overflow-y: auto;
                max-height: calc(
                  90vh - 28px - env(safe-area-inset-bottom, 0px)
                );
              }
            }
          }
        }
      `}</style>
    </>
  );
}
