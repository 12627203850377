import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStrapiURLClientSide } from "utils/api";
import { axiosGetRequest } from "utils/clientUtil";

const Button = dynamic(() => import("@mui/material/Button"));

export default function PwaAndroidDeviceDialog(props) {
  const { onClose, androidIcon } = props;
  const { t: tPublic } = useTranslation("public");
  const [manifest, setManifest] = useState({});
  const [installPromptEvent, setInstallPromptEvent] = useState();

  const showPwaPromptState = useSelector(
    (state) => state.general.showPwaPrompt
  );

  const fetchManifestJson = useCallback(async () => {
    const manifestResponse = await axiosGetRequest(
      getStrapiURLClientSide("/assets/manifest.json")
    );
    setManifest(manifestResponse.response.data);
  }, []);

  const handleInstall = () => {
    if (installPromptEvent) {
      installPromptEvent.prompt();
      installPromptEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          onClose(true);
        }
        setInstallPromptEvent(null);
      });
    }
  };

  const handleDismiss = () => {
    onClose(true);
  };

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      fetchManifestJson();
      setInstallPromptEvent(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, [installPromptEvent, fetchManifestJson]);

  return (
    <>
      {installPromptEvent ? (
        <>
          <div className="pwa-android-install-prompt">
            <div
              className={`dialog ${
                showPwaPromptState && manifest ? "available" : ""
              }`}
            >
              <div className="dialog-header">
                <img
                  className="dialog-image"
                  src={androidIcon}
                  alt={manifest.name}
                />
                <div className="dialog-title">
                  <span>{manifest.name}</span>
                  <small>{manifest.description}</small>
                </div>
              </div>
              <div className="dialog-body">
                {tPublic("pwaCheckAndroidPromptDescription")}
              </div>

              <div className="action-buttons">
                <Button variant="text" onClick={handleDismiss}>
                  {tPublic("pwaCheckAndroidPromptButtonDismiss")}
                </Button>
                <Button variant="contained" onClick={handleInstall}>
                  {tPublic("pwaCheckAndroidPromptButtonInstall")}
                </Button>
              </div>
            </div>
          </div>
          <style jsx>{`
            .pwa-android-install-prompt {
              user-select: none;

              .dialog {
                background-color: #ffffff;
                padding: 10px;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                -webkit-box-shadow: 0 0 8px -2px #000000;
                box-shadow: 0 0 8px -2px #000000;
                position: fixed;
                display: inline-block;
                transition: transform 0.4s cubic-bezier(0.33, 1, 0.66, 1),
                  opacity 0.5s cubic-bezier(1, 0, 1, -2),
                  border 0.35s ease-in-out;
                opacity: 0;
                transform: translateY(100%);

                &.available {
                  z-index: 10000;
                  bottom: 0;
                  opacity: 1;
                  pointer-events: unset;
                  transform: translateY(0);
                  transition: transform 0.5s cubic-bezier(0.33, 1, 0.66, 1),
                    border 0.35s ease-in-out;
                }
                .dialog-header {
                  display: flex;
                  align-items: center;
                  gap: 1rem;

                  .dialog-image {
                    width: 64px;
                    height: 64px;
                    border-radius: 6px;
                  }

                  .dialog-title {
                    display: flex;
                    flex-direction: column;
                    line-height: 20px;
                    font-size: 16px;
                    font-weight: bold;
                  }
                }
                .dialog-body {
                  padding: 10px 0;
                }

                .action-buttons {
                  display: flex;
                  justify-content: space-between;
                }
              }
            }
          `}</style>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
